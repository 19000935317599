<template>
  <modal :show.sync="show" body-classes="pt-0 pb-0" @close="closeLayer" >
    <h6 slot="header" class="modal-title">유선전화 번호 등록</h6>
    <template>
      <form method="post" action="#" role="form" class="needs-validation">
        <div class="form-group row">
          <label class="col-md-3 col-form-label form-control-label">발신번호 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.sendNumber"
                        name="sendNumber"
                        :error="_getError('sendNumber')"
                        placeholder="전화번호 입력"
                        v-validate="'required'"
                        :maxlength="15"
                        @keyup="phoneNoMask">
            </base-input>
          </div>
        </div>

        <div>
          <p class="mt-4" style="margin-top: 1rem;">
            <small><span class="text-danger font-weight-bold">유선번호</span> 등록시 제출서류를 <span class="text-primary font-weight-bold">kicm.dkt@kakaocorp.com </span>로 전송 <br/>
              또는 <span class="text-primary font-weight-bold">파일첨부</span>하여 주시길 바랍니다.</small>
          </p>
          <p class="mt-4">
            <small class="font-weight-bold">- 제출서류 </small><br/>
            <small>· 통신서비스 이용 증명원</small><br/>
          </p>
        </div>

        <div class="form-group row mb-0">
          <div class="col-md-10">
            <el-upload
              class="upload-demo"
              action="#"
              multiple
              drag
              :limit="3"
              :accept="acceptedFiles"
              :on-preview="handlePreview"
              :on-change="onChange"
              :auto-upload="false"
              :file-list="uploadFiles">

              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              <div class="el-upload__tip text-danger" slot="tip">첨부파일 당 용량 50MB 제한 (최대 3개)</div>
            </el-upload>
          </div>
        </div>

      </form>
    </template>
    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
      <base-button type="primary" class="ml-auto" @click="validateBeforeSubmit()">등록</base-button>
    </template>

  </modal>
</template>

<script>
import { Table, TableColumn, Upload ,Select, Option } from 'element-ui'
import commJsUtilMixin from '@/shared/mixins/commJsUtil'
import commValidatorMixin from '@/shared/mixins/commValidatorMixin'
import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE } from '@/shared/util/const'
import swal from "sweetalert2";

export default {
  inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
  name: 'PcbHomePhonRegisterLayer',
  mixins: [ commJsUtilMixin, commValidatorMixin ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Upload.name]: Upload,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
  },
  data() {
    return {
      acceptedFiles: 'application/pdf,application/zip',
      uploadFiles     : [],
      show: true,
      model: {
        sendNumber : null,
        approval : 'N'
      },
    }
  },
  created() {

  },
  methods: {
    /**
     * 유효성체크
     */
    validateBeforeSubmit(){
      let chk = true;
      if(this.checkNull(this.model.sendNumber)) {
        this.alertMsg('전화번호를 입력해 주세요.')
        chk = false;
      }
      if(chk) {
        this._validateBeforeSubmit(this.register());
      }
    },

    /**
     * 등록
     */
    register() {
      let formData = new FormData();

      for(let i=0, length = this.uploadFiles.length; i < length; i++) {
        if(this.uploadFiles[i].status === 'ready') {
          formData.append("files", this.uploadFiles[i].raw);
        }
      }

      formData.append("sendNumber", this.model.sendNumber);
      formData.append("approval", this.model.approval);

      this.$axios.post(
        `${USER_API_PREFIX}/sender/pcb-home-create`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then(res => {
        if (res.data.result.code === RESULT_CODE.SUCCESS) {
          this.$emit('close' , true);
          this.notify('default', res.data.result.message);
        } else {
          this.notify('warning', res.data.result.message);
        }
      });

    },


    /**
     * 레이어 닫기
     */
    closeLayer() {
      this.$emit('close');
    },

    /**
     * null check
     */
    checkNull(param) {
      if (param === undefined || param === null || '' == param) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
     */
    phoneNoMask() {
      this.model.sendNumber = this._autoHyphenPhoneNumber(this.model.sendNumber);
    },

    /**
     * 파일 선택 이벤트 핸들러
     * @param file
     */
    handlePreview(file) {
      if(file.status === 'success') {
        this.download(file.fileSeq)
      }
    },

    /**
     * 파일 등록 이벤트 핸들러
     * @param file
     * @param fileList
     */
    onChange(file, fileList) {
      if(file.status === 'ready') {
        const limitMb = 50;
        const maxSizeLimit = limitMb * 1024 * 1024;
        if(file.size > maxSizeLimit) {
          this.notify('warning', `(${file.name}) 첨부파일은 ${limitMb}MB를 초과할 수 없습니다.`);
          fileList.splice((fileList.length-1), 1)
        }
      }

     const isZip = file.name.endsWith('.zip');
     const isPdf = file.name.endsWith('.pdf');
      if (!isZip && !isPdf) {
        this.notify('warning', `.zip / .pdf 파일만 첨부 가능합니다.`);
        fileList.splice((fileList.length-1), 1)
      }
      this.uploadFiles = fileList;
    },
    /**
     * 알럿메시지 출력
     */
    alertMsg(msg , title){
      let titleMsg = title ? title : '경고';
      let type = title ? 'info' : 'warning';
      swal({
        title: titleMsg,
        text: msg,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-'+type,
        type: type,
        width: 400
      });
    },
    /**
     * 알림 메시지 생성
     * @param type
     */
    notify(type = 'default', message) {
      let notiMsg = message ? message : ''
      this.$notify({
        message: notiMsg,
        timeout: 5000,
        icon: 'ni ni-bell-55',
        type
      })
    }
  }
};
</script>

<style>
</style>
