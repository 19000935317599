<template>
  <modal :show.sync="show" body-classes="pt-0 pb-0" @close="closeLayer" >
    <h6 slot="header" class="modal-title">발신번호 등록</h6>
    <template>
      <form method="post" action="#" role="form" class="needs-validation" name="loginForm">
        <div class="form-group row mb-0">
          <div class="col-md-6">
            <base-button block type="primary" @click="showCellPhonLayer()" size="lg">휴대전화 번호 등록</base-button>
          </div>
          <div class="col-md-6">
            <base-button block type="danger" @click="showHomePhonLayer()" size="lg">유선전화 번호 등록</base-button>
          </div>
<!--          <div class="col-md-4">-->
<!--            <base-button block type="info">예외사업자 등록</base-button>-->
<!--          </div>-->
        </div>
      </form>
    </template>
    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
    </template>

    <!-- 휴대전화 등록 레이어 -->
    <pcb-cell-phone-register-layer
      v-if="cellPhoneShow"
      @close="closeCellPhonLayer">
    </pcb-cell-phone-register-layer>

    <!-- 유선전화 등록 레이어 -->
    <pcb-home-phone-register-layer
      v-if="homePhoneShow"
      @close="closeHomePhonLayer">
    </pcb-home-phone-register-layer>
  </modal>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import commJsUtilMixin from '@/shared/mixins/commJsUtil'
import commValidatorMixin from '@/shared/mixins/commValidatorMixin'
import PcbCellPhoneRegisterLayer from './PcbCellPhoneRegisterLayer'
import PcbHomePhoneRegisterLayer from './PcbHomePhoneRegisterLayer'

export default {
  inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
  name: 'PcbRegisterLayer',
  mixins: [ commJsUtilMixin, commValidatorMixin ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PcbCellPhoneRegisterLayer,
    PcbHomePhoneRegisterLayer
  },
  props: {
  },
  data() {
    return {
      show: true,
      cellPhoneShow : false,
      homePhoneShow : false
    }
  },
  created() {

  },
  methods: {

    /**
     * 레이어 닫기
     */
    closeLayer() {
      this.$emit('close');
    },

    /**
     * 휴대전화 등록 레이어 열기
     */
    showCellPhonLayer(){
      this.cellPhoneShow = true;
    },
    /**
     * 유선전화 등록 레이어 열기
     */
    showHomePhonLayer(){
      this.homePhoneShow = true;
    },
    /**
     * 휴대전화 등록 레이어 닫기
     */
    closeCellPhonLayer(register){
      this.cellPhoneShow = false;
      if(register){
        this.closeLayer();
      }
    },
    /**
     * 유선전화 등록 레이어 닫기
     */
    closeHomePhonLayer(register){
      this.homePhoneShow = false;
      if(register){
        this.closeLayer();
      }
    },

  }
};
</script>

<style>
</style>
