<template>
  <modal :show.sync="show" body-classes="pt-0 pb-0" @close="closeLayer" >
    <h6 slot="header" class="modal-title">휴대전화 번호 등록</h6>
    <template>
      <form method="post" action="#" role="form" class="needs-validation" name="loginForm">
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">발신번호 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.sendNumber"
                        name="sendNumber"
                        :error="_getError('sendNumber')"
                        placeholder="전화번호 입력"
                        v-validate="'required'"
                        :maxlength="15"
                        @keyup="phoneNoMask"
                        :readonly="otpStatus === 'Y'">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0" v-if="otpStatus === 'N'">
          <label class="col-md-3 col-form-label form-control-label">OTP</label>
          <div class="col-md-9">
            <base-button  type="primary" outline @click="requestOtp">OTP 발송</base-button>
          </div>
        </div>
        <div class="form-group row mb-0" v-if="otpStatus === 'R'" style="margin-top: 1rem;">
          <label class="col-md-3 col-form-label form-control-label">OTP인증 <span class="text-danger">*</span></label>
          <div class="col-md-7">
            <base-input group>
              <input type="text"
                     name="otp"
                     v-validate="'required'"
                     v-model="model.otp"
                     :maxlength="6"
                     class="form-control"
                     placeholder="인증번호 입력"
                     aria-describedby="button-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-primary" v-if="this.otpTime.otpProgressTime !== 0" type="button" @click="confirmOtp">인증</button>
                <button class="btn btn-outline-primary" v-else type="button" @click="requestOtp">재발송</button>
              </div>
            </base-input>
          </div>
          <div class="col-2 text-center" style="margin-top: 0.7rem;">
            <span class="text-danger">{{ this.otpTime.convertTime }}</span>
          </div>
        </div>
        <div>
        </div>
      </form>
    </template>
    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
      <base-button type="primary" class="ml-auto" @click="validateBeforeSubmit()">등록</base-button>
    </template>

  </modal>
</template>

<script>
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import commJsUtilMixin from '@/shared/mixins/commJsUtil'
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin'
  import {RESULT_CODE, USER_API_PREFIX} from '@/shared/util/const'
  import swal from "sweetalert2";

  export default {
  inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
  name: 'PcbCellPhonRegisterLayer',
  mixins: [ commJsUtilMixin, commValidatorMixin ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
  },
  data() {
    return {
      show: true,
      otpStatus : 'N',       //발송전 : N , 발송완료 확인전 : R , 확인완료 : Y
      model: {
        sendNumber : null,
        otp : '',
        approval : 'Y'
      },
      otpTimer : null,
      otpTime : {
        defaultTime: 180,
        otpProgressTime: 0,
        convertTime : '',
      }
    }
  },
  created() {

  },
  methods: {
    /**
     * 유효성체크
     */
    validateBeforeSubmit(){
      let chk = true;
      if('Y' != this.otpStatus){
        this.alertMsg('OTP 인증을 진행해 주세요.')
        chk = false;
      }
      if(this.checkNull(this.model.sendNumber)) {
        this.alertMsg('전화번호를 입력해 주세요.')
        chk = false;
      }
      if(chk){
        this._validateBeforeSubmit(this.register());
      }
    },

    /**
     * 등록
     */
    register() {
      this.$axios.post(`${USER_API_PREFIX}/sender/pcb-cell-create`, {
        sendNumber: this.model.sendNumber,
        approval  : this.model.approval
      }).then(res => {
        if (res.data.result.code === RESULT_CODE.SUCCESS) {
          this.$emit('close' , true);
          this.notify('default', res.data.result.message);
        } else {
          this.notify('warning', res.data.result.message);
        }
      }).catch(error => {
        console.log(error.response)
      })
    },

    /**
     * OTP 인증 요청
     */
    requestOtp() {
      let chk = true;
      if(this.checkNull(this.model.sendNumber)) {
        this.alertMsg('전화번호를 입력해 주세요.')
        chk = false;
      }

      let regExp = /[01](0|1|6|7|8|9)[-](\d{4}|\d{3})[-]\d{4}$/g;
      if(!regExp.test(this.model.sendNumber)){
        this.alertMsg('전화번호 형식이 올바르지 않습니다.')
        chk = false;
      }

      this.model.otp = '';
      if(chk){
        this.$axios.post(`${USER_API_PREFIX}/sender/request-otp`, {
          mobileNo  : this.model.sendNumber
        }).then(res => {
          if(res.data.result.code === RESULT_CODE.FAIL) {
            this.alertMsg(res.data.result.message);
          } else {
            this.otpStatus = 'R';
            // OTP 타이머 시작
            this.startOtpTimer();
          }
        }).catch(error => {
          console.log(error.response)
        })
      }
    },
    /**
     * OTP 인증 처리
     */
    confirmOtp() {
      let chk = true;
      if(this.checkNull(this.model.otp)) {
        this.alertMsg('OTP인증번호를 입력해 주세요.')
        chk = false;
      }
      if(this.otpTime.otpProgressTime == 0){
        this.alertMsg('인증시간이 초과하였습니다. 재인증을 진행해주세요.')
        chk = false;
      }

      if(chk){
        this.$axios.post(`${USER_API_PREFIX}/sender/confirm-otp`, {
          mobileNo  : this.model.sendNumber,
          otp       : this.model.otp
        }).then(res => {
          if(res.data.result.code === RESULT_CODE.FAIL) {
            this.alertMsg(res.data.result.message);
            this.model.otp = ''
          }else{
            this.otpStatus = 'Y';
            clearInterval(this.otpTimer);
          }
        }).catch(error => {
          console.log(error.response)
        })
      }
    },

    /**
     * OTP 타이머 시작
     */
    startOtpTimer() {
      this.otpTime.otpProgressTime = this.otpTime.defaultTime;
      this.convertSecondsToTimeFormat(this.otpTime.otpProgressTime)

      if(this.otpTimer != null){
        clearInterval(this.otpTimer);
      }
      this.otpTimer = setInterval(this.convertProgressTime, 1000)
    },
    /**
     * OTP 인증 남은시간 처리
     */
    convertProgressTime() {
      let timer = this.otpTime.otpProgressTime
      this.otpTime.otpProgressTime = timer - 1

      // 타이머 초를 분 단위로 변환
      this.convertSecondsToTimeFormat(this.otpTime.otpProgressTime)

      // 타이머 종료시 처리
      if(this.otpTime.otpProgressTime === 0) {
        clearInterval(this.otpTimer)
      }
    },
    /**
     * 초를 분 단위로 변환
     * @param convertSeconds
     */
    convertSecondsToTimeFormat(convertSeconds) {
      let minutes = Math.floor(convertSeconds / 60)
      let seconds = (convertSeconds % 60)
      this.otpTime.convertTime = minutes + ':' + this.zeroLeftleftPadding(seconds)
    },
    /**
     * 숫자가 1자리수일 때 2자리수로 변환
     * @param target
     * @returns {*}
     */
    zeroLeftleftPadding(target) {
      return target.toString().length === 1 ? ('0' + target) : target.toString()
    },

    /**
     * 레이어 닫기
     */
    closeLayer() {
      this.$emit('close');
    },

    /**
     * null check
     */
    checkNull(param) {
      if (param === undefined || param === null || '' == param) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
     */
    phoneNoMask() {
      this.model.sendNumber = this._autoHyphenPhoneNumber(this.model.sendNumber);
    },

    /**
     * 알럿메시지 출력
     */
    alertMsg(msg , title){
      let titleMsg = title ? title : '경고';
      let type = title ? 'info' : 'warning';
      swal({
        title: titleMsg,
        text: msg,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-'+type,
        type: type,
        width: 400
      });
    },
    /**
     * 알림 메시지 생성
     * @param type
     */
    notify(type = 'default', message) {
      let notiMsg = message ? message : ''
      this.$notify({
        message: notiMsg,
        timeout: 5000,
        icon: 'ni ni-bell-55',
        type
      })
    }
  }
};
</script>

<style>
</style>
