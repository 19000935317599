<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">발신번호 사전등록</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">발신번호 목록</h3>
            <div class="float-right">
              <base-button type="danger" block @click="showSelectLayer">발신번호 등록</base-button>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap p-0">
              <div class="col-1">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="col-6 form-group row mb-0">
                <div class="col-md-4 col-4">
                </div>
                <div class="col-md-8 col-8 pl-0">
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="발신번호" aria-label="발신번호 입력" aria-describedby="button-addon2"
                           v-model="search.sendNumber"
                           @keydown.enter="retrievePcbList(1)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" @click="retrievePcbList(1)">검색</button>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>

            <el-table :data="pcbList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'결과가 없습니다.'"
                      class="table-responsive table-flush">
              <el-table-column label="번호" min-width="10%">
                <template slot-scope="scope">
                  {{ pagination.total - (pagination.currentPage-1)*pagination.perPage-scope.$index }}
                </template>
              </el-table-column>
              <el-table-column prop="sendNumber" label="발신번호" min-width="18%"></el-table-column>
              <el-table-column prop="approval" label="승인여부" min-width="12%">
                <template slot-scope="scope">{{ scope.row.approval | approvalFilter }}</template>
              </el-table-column>
              <el-table-column prop="regBy" label="등록자" min-width="15%"></el-table-column>
              <el-table-column prop="regDate" label="등록일시" min-width="20%">
                <template slot-scope="scope">{{ scope.row.regDate | convertDatetimeFormat }}</template>
              </el-table-column>
              <el-table-column prop="remove" label="삭제" min-width="15%">
                <template v-slot="{row}">
                  <base-button type="primary" size="sm" @click="removePcm(row.pcbSeq, row.bizNo)">삭제</base-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="retrievePcbList">
            </base-pagination>
          </div>

          <!-- 발신번호 등록 레이어 -->
          <pcb-select-layer
            v-if="showLayer"
            @close="closeSelectLayer">
          </pcb-select-layer>

        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import commJsUtilMixin from '@/shared/mixins/commJsUtil'
  import { default as commFlatpickrMixin } from '@/shared/mixins/commFlatpickrMixin'
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX , RESULT_CODE} from '@/shared/util/const'
  import swal from "sweetalert2";
  import PcbSelectLayer from './PcbSelectLayer'

  export default {
    name: 'PcbList',
    mixins: [ commFlatpickrMixin, commJsUtilMixin ,commPaginationMixin],
    components: {
      [Select.name] : Select,
      [Option.name] : Option,
      [Table.name]  : Table,
      [TableColumn.name]: TableColumn,
      PcbSelectLayer
    },
    computed: {

    },
    filters: {
      approvalFilter: value => {
        let name = '';
        switch (value) {
          case 'Y':
            name = '승인';
            break;
          case 'N':
            name = '미승인';
            break;
        }
        return name;
      },
    },
    created() {
      this.retrievePcbList();
    },
    data() {
      return {
        pcbList : [] ,
        showLayer : false,
        pcbSeq  : '',
        search  : {
          sendNumber : ''
        }
      }
    },
    methods: {
      /**
       * 발신번호 목록조회
       */
      retrievePcbList(page) {
        let pageNo = page ? page : this.pagination.currentPage;
        this.$axios.get(`${USER_API_PREFIX}/sender/pcb-list`, {
          params : {
            sendNumber     : this.search.sendNumber,
            page           : pageNo,
            rows           : this.pagination.perPage
          }
        }).then(res => {
          this.pcbList                = res.data.result.pcbList;        // 목록정보 세팅
          this.pagination.currentPage = pageNo;                         // 페이지 번호 세팅
          this.pagination.total       = res.data.result.total;          // 전체페이지수 세팅
        });
      },

      /**
       * 발신번호 삭제
       */
      removePcm(pcbSeq, bizNo) {
        swal({
          title: `발신번호를 삭제하시겠습니까?`,
          text: '삭제시 해당 발신번호로 발송이 불가합니다.',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            this.$axios.post(`${USER_API_PREFIX}/sender/pcb-remove`, {
              pcbSeq: pcbSeq,
              bizNo: bizNo
            }).then(res => {
              if (res.data.result.code === RESULT_CODE.SUCCESS) {
                this.notify('default', res.data.result.message);
                this.retrievePcbList(1);
              } else {
                this.notify('warning', res.data.result.message);
              }
            });
          }
        });
      },
      /**
       * 등록 레이어 생성
       */
      showSelectLayer() {
        this.showLayer = true
      },

      /**
       * 등록/조회 레이어 닫기
       */
      closeSelectLayer() {
        this.showLayer = false
        this.retrievePcbList(1);
      },

      /**
       * 알럿메시지 출력
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },

      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        this.$notify({
          message: message,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    }
  }
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
